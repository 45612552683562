.nft_image {
  width: 100%;
}

.walletButton {
  position: fixed;
  top: 6px;
  right: 6px;
  z-index: 2;
}

.discordButton {
  position: fixed;
  top: 3px;
  right: 170px;
  z-index: 2;
}

.discordButtonImg {
  width: 60px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}